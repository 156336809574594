import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import Breadcrumb from './Breadcrumb';
import Dots from '../../components/home/v2/Dots';
import { mobileThresholdPixels, colors, fontSizes, margins, ContainerBlock, Column, Title2 }
  from '../../components/home/v2/StyledComponents';
import { toFormattedText, toFormattedLinkedText } from '../../services/formatting';

const StyledContainerBlock = styled(ContainerBlock) `
  margin-bottom: ${margins.m};
`;

const TextContainer = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  margin: auto;
  text-align: center;
  color: ${colors.navy};
  width: 900px;
  ${props => !props.withoutTitle && `
    margin-bottom: ${margins.m};
    text-align: justify;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const FootNote = ({ title, paragraph, links, itemListElement, context: { isMobile } }) => (
  <StyledContainerBlock>
    {title &&
      <Column justifyContent={'center'} alignItems={'center'}>
        <Title2>{!isMobile ? toFormattedText(title) : title.split('\n').join(' ')}</Title2>
        <Dots />
      </Column>
    }
    <TextContainer withoutTitle={!title}>
      {toFormattedLinkedText(paragraph, links)}
    </TextContainer>
    {itemListElement && <Breadcrumb itemListElement={itemListElement} />}
  </StyledContainerBlock>
);

FootNote.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  itemListElement: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

FootNote.defaultProps = {
  title: '',
  itemListElement: null,
};

export default withAppContext(FootNote);
