import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { toFormattedText, slashNtoBr } from '../../services/formatting';
import routesMap from '../../Routes';
import Dots from '../../components/home/v2/Dots';
import { trackEvent } from '../../services/analytics';
import {
  colors, fontSizes, fontWeight, margins, mobileThresholdPixels,
  Title2, Title3, Subtitle2, ButtonContainer, Button, ContainerBlock, Column,
} from '../../components/home/v2/StyledComponents';

import image1 from '../../json/images/landingPage/home/howItWorks/PhotoHowItworks1.jpg';
import image1TheKooples from '../../json/images/landingPage/home/howItWorks/TheKooples1.jpg';
import image1GaleriesLafayette from '../../json/images/landingPage/home/howItWorks/GaleriesLafayette1.jpg';
import image1Caroll from '../../json/images/landingPage/home/howItWorks/Caroll1.jpg';
import image2 from '../../json/images/landingPage/home/howItWorks/PhotoHowItworks2.jpg';
import image3 from '../../json/images/landingPage/home/howItWorks/PhotoHowItworks3.jpg';

const StyledTitle3 = styled(Title3)`
  font-size: ${fontSizes.m};
  font-weight: bold;
  width: 70%;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: left;
    width: 95%;
  }
`;

const SubContainer = styled.div`
  color: ${colors.navy};
  display: flex;
  align-items: stretch;
  align-content: center;
  margin: 0px;
  ${props => props.first && `margin-top: ${margins.l}`};
  ${props => props.last && `margin-bottom: ${margins.l}`};
  flex-direction: ${props => props.imgFirst ? 'row' : 'row-reverse'};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: ${margins.s} 0px;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  object-fit: cover;
  position: relative;
  width: 100%;
  max-height: 400px;
`;

const StyledButtonContainer = styled(ButtonContainer)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.s};
  }
`;

const TextMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: ${margins.m} 0px;
    ${props => props.last && 'margin-bottom: 0px'};
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: ${fontWeight.light};
  color: ${colors.navy};
  line-height: 22px;
  width: 70%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 95%;
    font-size: ${fontSizes.s};
  }
`;

const HowItWorks = ({ subTitle, blocks }) => (
  <ContainerBlock id="HowItWorks">
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2>Comment ça marche</Title2>
      <Dots />
      <Subtitle2>{toFormattedText(subTitle)}</Subtitle2>
    </Column>
    {blocks.map((block, index) =>
      <SubContainer
        imgFirst={(index % 2) === 0} key={block.title}
        first={index === 0} last={index === blocks.length - 1}
      >
        <ImgContainer>
          <Img src={block.image.publicURL} alt={block.imageAlt} />
        </ImgContainer>
        <TextMainContainer imgFirst={(block.index % 2) === 0} last={index === blocks.length - 1}>
          <StyledTitle3>{block.title}</StyledTitle3>
          <TextContainer>{slashNtoBr(block.paragraph)}</TextContainer>
          {(index === blocks.length - 1) &&
            <StyledButtonContainer>
              <Link
                to={routesMap.Step1.url}
                onClick={() => trackEvent('click', 'prendreRDV_block-comment-ca-marche')}
              >
                <Button>Prendre RDV</Button>
              </Link>
            </StyledButtonContainer>
          }
        </TextMainContainer>
      </SubContainer>,
    )}
  </ContainerBlock>
);

HowItWorks.propTypes = {
  subTitle: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    image: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    imageAlt: PropTypes.string.isRequired,
  })),
};

const b2cDefaultProps = {
  subTitle: 'Vos retouches livrées chez vous \nen trois étapes.',
  blocks: [
    {
      title: '1. Réservez un rendez-vous à domicile avec votre couturier',
      paragraph: `Vous commandez votre retouche sur notre site ou
        sur notre appli mobile. Selon les retouches nécessaires,
        les différentes matières à travailler et leur disponibilité,
        Tilli choisit l'un de ses couturiers professionnels.`,
      image: { publicURL: image1 },
      imageAlt: 'Un téléphone connecté sur le site de Tilli.',
    },
    {
      title: '2. Votre couturier vient prendre vos mesures et vous conseiller chez vous',
      paragraph: 'Vous ne serez pas débité avant ce rendez-vous. Prévoyez un créneau de 30 minutes :)',
      image: { publicURL: image2 },
      imageAlt: "Une couturière qui ajuste la robe d'une cliente.",
    },
    {
      title: '3. Vos vêtements sont livrés a domicile',
      paragraph: `Tilli se charge de  livrer vos vêtements retouchés au lieu convenu
        au premier rendez-vous, au moment préalablement choisi.`,
      image: { publicURL: image3 },
      imageAlt: 'Un pantalon rendu au client après une retouche faite par Tilli.',
    },
  ],
};

function getImage1() {
  if (process.env.GATSBY_BRAND === 'THE_KOOPLES') return image1TheKooples;
  if (process.env.GATSBY_BRAND === 'GALERIES_LAFAYETTE') return image1GaleriesLafayette;
  if (process.env.GATSBY_BRAND === 'CAROLL') return image1Caroll;
  return image1;
}

function getParagraph1() {
  // eslint-disable-next-line max-len
  if (process.env.GATSBY_BRAND === 'THE_KOOPLES') return 'Vous pouvez faire une estimation des retouches ou passer cette étape. Les infos de votre client vous seront demandées. Vous pourrez offrir à la charge de The Kooples la retouche en cliquant sur le bouton “retouche offerte”.\n\nService 7j/7 de 9h à 22h';
  // eslint-disable-next-line max-len
  if (process.env.GATSBY_BRAND === 'GALERIES_LAFAYETTE') return 'Vous pouvez faire une estimation des retouches ou passer cette étape. Les infos de votre client vous seront demandées. Vous pourrez offrir à la charge des Galeries Lafayette la retouche en cliquant sur un des 3 boutons “remise”.\n\nService 7j/7 de 9h à 22h';
  // eslint-disable-next-line max-len
  return 'Vous pouvez faire une estimation des retouches ou passer cette étape. Les infos de votre client vous seront demandées.\n\nService 7j/7 de 9h à 22h';
}

const brandsDefaultProps = {
  subTitle: 'Prenez RDV avec votre client on\ns’occupe du reste',
  blocks: [
    {
      title: '1. Prise de rdv en boutique pour votre client',
      paragraph: getParagraph1(),
      image: { publicURL: getImage1() },
      imageAlt: 'Capture écran plateforme The Kooples.',
    },
    {
      title: '2. Un couturier se rendra au domicile de votre client',
      // eslint-disable-next-line max-len
      paragraph: 'Le couturier épinglera et conseillera votre client au mieux.\nLe client ne sera débité qu’à la suite de ce rendez-vous sur validation des retouches à éffectuer avec son couturier.',
      image: { publicURL: image2 },
      imageAlt: "Une couturière qui ajuste la robe d'une cliente.",
    },
    {
      title: '3. Les vêtements sont livrés par coursier à domicile sous 3 jours',
      paragraph: 'Tilli se charge de  livrer les vêtements retouchés au domicile de votre client.',
      image: { publicURL: image3 },
      imageAlt: 'Un pantalon rendu au client après une retouche faite par Tilli.',
    },
  ],
};

HowItWorks.defaultProps = process.env.GATSBY_BRAND ? brandsDefaultProps : b2cDefaultProps;

export default HowItWorks;
