import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontSizes, mobileThresholdPixels } from '../../components/styledComponents';

const Container = styled.div`
  background-color: ${colors.sand};
  margin: 0px 100px;
  padding: 20px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    padding: 10px 0px;
  }
`;

const Text = styled.div`
  color: ${colors.navy};
  text-align:center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 2px;
    font-size: ${fontSizes.s};
  }
`;

const ReferrerBanner = ({ referrer }) => (
  <Container>
    <Text>
      {referrer.firstname} t’envoie une bonne intention, 5€ offerts avec le code {referrer.referrerCode}
    </Text>
  </Container>
);

ReferrerBanner.propTypes = {
  referrer: PropTypes.shape({}).isRequired,
};

export default ReferrerBanner;
