import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ViewPager, Frame, Track, View } from 'react-view-pager';
import { Link } from 'gatsby';

import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import { toFormattedText } from '../../services/formatting';
import Dots from '../../components/home/v2/Dots';
import {
  colors, mobileThresholdPixels, fontSizes, fontWeight, margins,
  ContainerBlock, Title2, Subtitle2, Column, RelativePosition,
} from '../../components/home/v2/StyledComponents';
import arrowLeft from '../../assets/arrow.left.navy.svg';
import arrowRight from '../../assets/arrow.right.navy.svg';
import arrowEmpty from '../../assets/headers/arrow.empty.png';
import arrowRightUp from '../../assets/whyTilli/L.reversed.white.svg';
import arrowLeftDown from '../../assets/whyTilli/L.white.svg';

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: isMobile() ? '68vw' : '1056px',
  margin: isMobile() ? '0px' : 'auto',
};

const viewsToShow = isMobile() ? 1 : 4;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const BlocksContainer = styled.div`
  display: flex;
  align-items: stretch;
  align-content: center;
  flex: 1 1 auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
    flex-wrap: wrap;
  }
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  ${props => !props.last ? `margin: 0px ${margins.m} 0px 0px` : ''};
  ${props => props.noMargin && 'margin 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const CautionContainer = styled.h2`
  color: ${colors.navy};
  font-size: ${fontSizes.m};
  font-family: Gotham;
  font-weight: ${fontWeight.medium};
  text-align: center;
  margin: ${margins.s} 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${props => !props.last ? `${margins.s} 0px;` : `${margins.s} 0px 0px 0px;`};
  }
`;

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 25px;
  margin: -10% ${margins.m} ${margins.m};

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    width: 14px;
    margin: -20% 10px ${margins.m};
  }
`;

const Img = styled.img`
  width: 235px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 85%;
  }
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Side = styled.div`
  ${props => props.left && `
    display: flex;
    align-items: flex-end;
  `}
  height: 50%;
  width: 25%;
`;

const ArrowImg = styled.img`
  width: 90%;
  ${props => props.isRotate && 'transform : rotate(90deg)'};
`;

const Block = (item, last, noMargin, rotate, link = null, trackingId = null) => (
  link !== null ?
    <BlockContainer key={item.imageAlt} last={last} noMargin={noMargin}>
      <Link to={link} onClick={() => trackEvent('click', trackingId)}>
        <RelativePosition center={isMobile()}>
          <Img src={item.image.publicURL} alt={item.imageAlt} />
          <ArrowsContainer>
            <Side left={!rotate}>
              <ArrowImg left isRotate={rotate} src={arrowLeftDown} alt={item.imageAlt} />
            </Side>
            <Side left={rotate}>
              <ArrowImg right isRotate={rotate} src={arrowRightUp} alt={item.imageAlt} />
            </Side>
          </ArrowsContainer>
        </RelativePosition>
      </Link>
      <Link
        to={link} onClick={() => trackEvent('click', trackingId)}
        style={{ textDecoration: 'none', color: `${colors.navy}` }}
      >
        <CautionContainer>{toFormattedText(item.caution)}</CautionContainer>
      </Link>
    </BlockContainer> :
    <BlockContainer key={item.imageAlt} last={last} noMargin={noMargin}>
      <RelativePosition center={isMobile()}>
        <Img src={item.image.publicURL} alt={item.imageAlt} />
        <ArrowsContainer>
          <Side left={!rotate}>
            <ArrowImg left isRotate={rotate} src={arrowLeftDown} alt={item.imageAlt} />
          </Side>
          <Side left={rotate}>
            <ArrowImg right isRotate={rotate} src={arrowRightUp} alt={item.imageAlt} />
          </Side>
        </ArrowsContainer>
      </RelativePosition>
      <CautionContainer>{toFormattedText(item.caution)}</CautionContainer>
    </BlockContainer>
);

class OurServices extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.state = { noLeftArrow: true, noRightArrow: false };
  }

  next() {
    if (!this.state.noRightArrow) this.track.next();
  }

  prev() {
    if (!this.state.noLeftArrow) this.track.prev();
  }

  render() {
    const { subTitle, imageList } = this.props;
    return (
      <ContainerBlock>
        <Column justifyContent={'center'} alignItems={'center'}>
          <Title2>Nos services</Title2>
          <Dots />
          <Subtitle2>{toFormattedText(subTitle)}</Subtitle2>
        </Column>
        <SubContainer>
          {imageList.length <= 4 ?
            <BlocksContainer>
              {imageList.map((item, index) =>
                Block(
                  item, index === imageList.length - 1, false, index % 2 === 0,
                  item.link, item.trackingId,
                ),
              )}
            </BlocksContainer> :
            <BlocksContainer>
              <ControlContainer>
                <ControlImg
                  alt="Photo précédente de retoucherie"
                  src={this.state.noLeftArrow ? arrowEmpty : arrowLeft}
                  onClick={() => this.prev()}
                />
              </ControlContainer>
              <ViewPager tag="main" style={viewPagerStyle}>
                <Frame className="frame">
                  <Track
                    ref={(c) => { this.track = c; }} viewsToShow={viewsToShow} className="track"
                    swipe={false}
                    onViewChange={currentIndexes => this.setState({
                      noLeftArrow: currentIndexes[0] === 0,
                      noRightArrow: currentIndexes[0] === imageList.length - viewsToShow,
                    })}
                  >
                    {imageList.map((item, index) =>
                      <View className="view" key={item.imageAlt} >
                        {Block(item, false, true, index % 2 === 0)}
                      </View>,
                    )}
                  </Track>
                </Frame>
              </ViewPager>
              <ControlContainer>
                <ControlImg
                  alt="Photo suivante de retoucherie"
                  src={this.state.noRightArrow ? arrowEmpty : arrowRight}
                  onClick={() => this.next()}
                />
              </ControlContainer>
            </BlocksContainer>
          }
        </SubContainer>
      </ContainerBlock>
    );
  }
}

OurServices.propTypes = {
  subTitle: PropTypes.string.isRequired,
  imageList: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    imageAlt: PropTypes.string.isRequired,
    caution: PropTypes.string.isRequired,
    link: PropTypes.string,
    trackingId: PropTypes.string,
  })).isRequired,
};

export default OurServices;
